import React from 'react';
import styled from 'styled-components';

import Page from '~/layout/LayoutMarketing';
import LargeCTA from '~/components/LargeCTA';
import Opener, { USE_CASES } from '~/components/use-cases/Opener';
import Image from '~/components/Image';
import QuotesSection from '~/components/use-cases/QuotesSection';
import UseCaseSection, {
  UseCaseScreenshot,
} from '~/components/use-cases/UseCaseSection';

const Styled = styled.div`
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .container__image {
    flex: 1 1 auto;
  }
  .container__text {
    flex: 0 0 50%;
  }
  .integrations__logos {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
  .integrations__logos__logo {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 1rem;
  }
`;

const INTEGRATION_LOGOS = ['jira', 'github', 'gitlab', 'clubhouse', 'linear'];

const ConsolidateFeedback: React.FC = () => {
  return (
    <Page title={USE_CASES[2].title}>
      <Styled>
        <Opener sectionIndex={2} />
        <UseCaseSection
          gray={true}
          orientation="center"
          title={
            <>Integrations bring customer feedback to your development team.</>
          }
          description={
            <>
              Herald integrates with JIRA, GitHub, GitLab, Clubhouse, Linear and
              more to bring the voice of the customer to your design and
              engineering teams, helping them design better solutions with a
              deeper understanding of customer pain points.
            </>
          }
          textStyle={{ maxWidth: '42rem' }}
          contentStyle={{ height: '3.5rem' }}
          content={
            <div className="integrations__logos">
              {INTEGRATION_LOGOS.map((i) => (
                <div className="integrations__logos__logo" key={i}>
                  <Image src={`logos/${i}.png`} />
                </div>
              ))}
            </div>
          }
        />
        <UseCaseSection
          orientation="left"
          title={
            <>
              Voice of the&nbsp;Customer reports&nbsp;show the week
              at&nbsp;a&nbsp;glance.
            </>
          }
          description={
            <>
              Herald sends a weekly Voice of the Customer report to your entire
              team which provide a birds eye view of the top issues reported by
              customers for the given week. The report also includes the top
              customer quotes to ensure that customer feedback is not lost in
              translation.
            </>
          }
          textStyle={{ maxWidth: '25rem' }}
          contentScreenshot={true}
          content={
            <UseCaseScreenshot>
              <Image src="screenshots/voice-of-the-customer.png" />
            </UseCaseScreenshot>
          }
        />
        <UseCaseSection
          gray={true}
          orientation="center"
          title={
            <>
              Slack notifications keep the team in sync on emerging&nbsp;issues.
            </>
          }
          description={
            <>
              Herald can also be configured to send notifications to a Slack
              channel about new issues so your team can stay in the loop on all
              emerging customer concerns.
            </>
          }
          contentScreenshot={true}
          content={
            <UseCaseScreenshot>
              <Image src="screenshots/take-slack.png" />
            </UseCaseScreenshot>
          }
        />
        <QuotesSection sectionId="align" />
        <LargeCTA title="Ready to align your team to think and serve customers first? " />
      </Styled>
    </Page>
  );
};

export default ConsolidateFeedback;
